export enum PlanType {
    Free = 0,
    FamiliesAnnually2019 = 1,
    TeamsMonthly2019 = 2,
    TeamsAnnually2019 = 3,
    EnterpriseMonthly2019 = 4,
    EnterpriseAnnually2019 = 5,
    Custom = 6,
    FamiliesAnnually = 7,
    TeamsMonthly = 8,
    TeamsAnnually = 9,
    EnterpriseMonthly = 10,
    EnterpriseAnnually = 11,
}
