import {
    SyncCipherNotification,
    SyncFolderNotification,
    SyncSendNotification,
} from '../models/response/notificationResponse';

export abstract class SyncService {
    syncInProgress: boolean;

    getLastSync: () => Promise<Date>;
    setLastSync: (date: Date) => Promise<any>;
    fullSync: (forceSync: boolean, allowThrowOnError?: boolean) => Promise<boolean>;
    syncUpsertFolder: (notification: SyncFolderNotification, isEdit: boolean) => Promise<boolean>;
    syncDeleteFolder: (notification: SyncFolderNotification) => Promise<boolean>;
    syncUpsertCipher: (notification: SyncCipherNotification, isEdit: boolean) => Promise<boolean>;
    syncDeleteCipher: (notification: SyncFolderNotification) => Promise<boolean>;
    syncUpsertSend: (notification: SyncSendNotification, isEdit: boolean) => Promise<boolean>;
    syncDeleteSend: (notification: SyncSendNotification) => Promise<boolean>;
}
