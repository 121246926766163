import {
    Component,
    OnInit,
} from '@angular/core';

import { MessagingService } from 'jslib-common/abstractions/messaging.service';
import { PlatformUtilsService } from 'jslib-common/abstractions/platformUtils.service';
import { SyncService } from 'jslib-common/abstractions/sync.service';
import { TokenService } from 'jslib-common/abstractions/token.service';
import { UserService } from 'jslib-common/abstractions/user.service';

import { Provider } from 'jslib-common/models/domain/provider';

@Component({
    selector: 'app-navbar',
    templateUrl: 'navbar.component.html',
})
export class NavbarComponent implements OnInit {
    selfHosted = false;
    name: string;
    email: string;
    providers: Provider[] = [];

    constructor(private messagingService: MessagingService, private platformUtilsService: PlatformUtilsService,
        private tokenService: TokenService, private userService: UserService, private syncService: SyncService) {
        this.selfHosted = this.platformUtilsService.isSelfHost();
    }

    async ngOnInit() {
        this.name = await this.tokenService.getName();
        this.email = await this.tokenService.getEmail();
        if (this.name == null || this.name.trim() === '') {
            this.name = this.email;
        }

        // Ensure provides are loaded
        if (await this.syncService.getLastSync() == null) {
            await this.syncService.fullSync(false);
        }
        this.providers = await this.userService.getAllProviders();
    }

    lock() {
        this.messagingService.send('lockVault');
    }

    logOut() {
        this.messagingService.send('logout');
    }
}
